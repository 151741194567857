import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/AppContext";
import Axios from "../../axios/axiosInstance";
import SubsModal from "./SubsModal";
import Pagination from "../../widgets/Pagination";
import moment from 'moment';
import { Overlay, Tooltip } from "react-bootstrap";

const Subscription = () => {
  const [Subscription, setSubscription] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [value, onChange] = useState(new Date());

  const [sortKey, setSortKey] = useState("");
  const [sortByOrder, setSortByOrder] = useState(""); // "asc" or "desc"
  const [show, setShow] = useState(false);
  const [showS, setShowS] = useState(false);
  const target = useRef(null);
  const targetS = useRef(null);
  const { spinner, setSpinner } = useContext(AppContext);
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));
  const [hasAccess, setHasAccess] = useState(null); // null means no filter
  const [usedCoupon, setUsedCoupon] = useState(null); // null means no filter

  const getSubscriptionList = (page, search, sortKey, sortByOrder) => {
    setSpinner(true);
    const params = new URLSearchParams({
      page,
      limit: 15,
      ...(debouncedSearch && { search: debouncedSearch }),
      ...(sortKey && { sortKey }),
      ...(sortByOrder && { sortByOrder }),
      ...(hasAccess !== null && { hasAccess }),
      ...(usedCoupon !== null && { usedCoupon }),
    });
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/freeMembershipUsers?${params}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then(function (response) {
        setSubscription(response.data);
        setSpinner(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSort = (key) => {
    if (sortKey === key) {
      if (sortByOrder === "ASC") {
        setSortByOrder("DESC");
      } else if (sortByOrder === "DESC") {
        setSortKey("");
        setSortByOrder("");
      } else {
        setSortByOrder("ASC");
      }
    } else {
      setSortKey(key);
      setSortByOrder("ASC");
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500); // Adjust the debounce time as needed

    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  useEffect(() => {
    getSubscriptionList(page, debouncedSearch, sortKey, sortByOrder, hasAccess, usedCoupon);
  }, [page, debouncedSearch, sortKey, sortByOrder, hasAccess, usedCoupon]);




  // const handleClickOutside = (event) => {
  //   if (target.current && !target.current.contains(event.target) && show) {
  //     setShow(false);
  //   }
  //   if (targetS.current && !targetS.current.contains(event.target) && showS) {
  //     setShowS(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [show, showS]);

  const toggleShow = () => {
    setShow(prev => {
      if (prev) {
        return false; // Close if currently open
      } else {
        setShowS(false); // Close other tooltip
        return true; // Open current tooltip
      }
    });
  };

  const toggleShowS = () => {
    setShowS(prev => {
      if (prev) {
        return false; // Close if currently open
      } else {
        setShow(false); // Close other tooltip
        return true; // Open current tooltip
      }
    });
  };


  function unixTimestampToCustomDate(timestamp) {
    if (!timestamp) {
      return '';
    }
    const date = new Date(parseInt(timestamp, 10));
    if (isNaN(date)) {
      return '';
    }
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }
  return (
    <div className="body-bg">
      {/* {state.preloader && (
      <div id="preloader">
        <div className="loader"></div>
      </div>
    )} */}
      <div className="horizontal-main-wrapper">
        {/* <Header _this={this} history={props.history} /> */}
        <div
          className="main-content-inner"
        // onClick={() => setState({ showMenu: false })}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="listHead pb-3">
                        Users
                      </p>
                      <span className="pr-2 mb-3 position-relative mobSearch searchtext">
                        <input
                          className="form-control searchEle"
                          type="search"
                          placeholder="search by phone no. or email"
                          value={search}
                          onChange={(e) => { setSearch(e.target.value); setPage(1) }}
                        />
                        <i className="fa fa-search searchIcon"></i>
                      </span>
                      {/* <span>
                          <input
                            className="btn btn-info  float-right"
                            data-toggle="modal"
                            data-target=".bd-example-modal-sm"
                            type="submit"
                            value="Add New"
                          />
                        </span> */}
                    </div>
                    <div className="single-table">
                      <div className="table-responsive">

                        <table className="table table-hover progress-table text-center" >
                          <thead className="text-uppercase">
                            <tr>
                              <th scope="col" className="w-10 cusror-pointer" style={{ whiteSpace: "nowrap" }} onClick={() => { handleSort("User.id"); setPage(1) }}>USER ID
                                <span className="sorting-arrows">
                                  <i
                                    className={`arrow-up ${sortKey === "User.id" && sortByOrder === "ASC" ? "active" : ""}`}

                                  ></i>
                                  <i
                                    className={`arrow-down ${sortKey === "User.id" && sortByOrder === "DESC" ? "active" : ""}`}

                                  ></i>
                                </span>
                              </th>
                              <th scope="col" className="w-15 cusror-pointer" onClick={() => { handleSort("UserMembership.purchased_at_ms"); setPage(1) }} style={{ whiteSpace: "nowrap" }}>SUBSCRIPTION START DATE
                                <span className="sorting-arrows">
                                  <i
                                    className={`arrow-up ${sortKey === "UserMembership.purchased_at_ms" && sortByOrder === "ASC" ? "active" : ""}`}

                                  ></i>
                                  <i
                                    className={`arrow-down ${sortKey === "UserMembership.purchased_at_ms" && sortByOrder === "DESC" ? "active" : ""}`}

                                  ></i>
                                </span>
                              </th>
                              <th scope="col" className="w-15 cusror-pointer" onClick={() => { handleSort("UserMembership.expiration_at_ms"); setPage(1) }} style={{ whiteSpace: "nowrap" }}>SUBSCRIPTION END DATE
                                <span className="sorting-arrows">
                                  <i
                                    className={`arrow-up ${sortKey === "UserMembership.expiration_at_ms" && sortByOrder === "ASC" ? "active" : ""}`}

                                  ></i>
                                  <i
                                    className={`arrow-down ${sortKey === "UserMembership.expiration_at_ms" && sortByOrder === "DESC" ? "active" : ""}`}

                                  ></i>
                                </span>
                              </th>
                              <th scope="col" className="w-10 cusror-pointer" onClick={() => {handleSort("User.created_at"); setPage(1)}} style={{ whiteSpace: "nowrap" }}>SIGN UP DATE
                                <span className="sorting-arrows">
                                  <i
                                    className={`arrow-up ${sortKey === "User.created_at" && sortByOrder === "ASC" ? "active" : ""}`}

                                  ></i>
                                  <i
                                    className={`arrow-down ${sortKey === "User.created_at" && sortByOrder === "DESC" ? "active" : ""}`}

                                  ></i>
                                </span>
                              </th>
                              <th scope="col" className="w-25">COUNTRY CODE</th>
                              <th scope="col" className="w-25">PHONE NUMBER</th>
                              <th scope="col" className="w-20">EMAIL</th>
                              <th scope="col" className="w-15" style={{ whiteSpace: "nowrap" }}>
                                HAS SUBSCRIPTION
                                <span ref={target} onClick={toggleShow} className="pointer">
                                  <img src="assets/image/filter.svg" alt="filter" />
                                </span>

                                <Overlay target={target.current} show={show} placement="bottom" >
                                  {(props) => (

                                    <Tooltip id="overlay-example" {...props} className="cuttool">

                                      <ul className="ps-0">
                                        <li className="d-flex align-items-center">
                                          <input
                                            type="checkbox"
                                            className="checkbox"
                                            id="yeschk"
                                            checked={hasAccess === "1"}
                                            onChange={() => {
                                              setHasAccess(hasAccess === "1" ? null : "1"); // Toggle between YES and null
                                              setPage(1)
                                            }}
                                          />

                                          {/* <input type="checkbox" className="checkbox" id="yeschk" onChange={(e) => setHasAccess(e.target.value)}/> */}
                                          <label
                                            className="mb-0 ml-2" htmlFor="yeschk">YES</label>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <input
                                            type="checkbox"
                                            className="checkbox"
                                            id="nochk"
                                            checked={hasAccess === "0"}
                                            onChange={() => {
                                              setHasAccess(hasAccess === "0" ? null : "0"); // Toggle between NO and null
                                              setPage(1)
                                            }}
                                          />
                                          {/* <input type="checkbox" className="checkbox" id="nochk" /> */}
                                          <label
                                            className="mb-0 ml-2" htmlFor="nochk">NO</label>
                                        </li>
                                      </ul>
                                    </Tooltip>
                                  )}
                                </Overlay>
                              </th>
                              <th scope="col" className="w-15">USED COUPON?
                                <span ref={targetS} onClick={toggleShowS} className="pointer">
                                  <img src="assets/image/filter.svg" alt="filter" />
                                </span>

                                <Overlay target={targetS.current} show={showS} placement="bottom" >
                                  {(props) => (

                                    <Tooltip id="overlay-example" {...props} className="cuttool">

                                      <ul className="ps-0">
                                        <li className="d-flex align-items-center">
                                          <input
                                            type="checkbox"
                                            className="checkbox"
                                            id="yeschk"
                                            checked={usedCoupon === "1"}
                                            onChange={() => {
                                              setUsedCoupon(usedCoupon === "1" ? null : "1"); // Toggle between YES and null
                                              setPage(1)
                                            }}
                                          />
                                          {/* <input type="checkbox" className="checkbox" id="yeschk" /> */}
                                          <label
                                            className="mb-0 ml-2" htmlFor="yeschk">YES</label>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <input
                                            type="checkbox"
                                            className="checkbox"
                                            id="nochk"
                                            checked={usedCoupon === "0"}
                                            onChange={() => {
                                              setUsedCoupon(usedCoupon === "0" ? null : "0"); // Toggle between NO and null
                                              setPage(1)
                                            }}
                                          />
                                          {/* <input type="checkbox" className="checkbox" id="nochk" /> */}
                                          <label
                                            className="mb-0 ml-2" htmlFor="nochk">NO</label>
                                        </li>
                                      </ul>
                                    </Tooltip>
                                  )}
                                </Overlay>
                              </th>
                              <th scope="col" className="w-10">ACTION</th>
                            </tr>
                          </thead>
                          <tbody>

                            {Subscription?.data?.length > 0 ? Subscription?.data?.map((items) =>
                              <tr >
                                <th scope="row">{items.id}</th>
                                {console.log("items", items)}
                                <td>{items?.UserMembership == null ? "-" : unixTimestampToCustomDate(items?.UserMembership?.purchased_at_ms)}</td>
                                <td>{items?.UserMembership == null ? "-" : unixTimestampToCustomDate(items?.UserMembership?.expiration_at_ms)}</td>
                                <td>
                                  {moment(items?.created_at).format('MM-DD-YYYY')}
                                  {/* {unixTimestampToCustomDate(items.created_at.split("T")[0])}  */}
                                  {/* {items.created_at.split("T")[0]} */}
                                </td>
                                <td>{items.phoneCode ? items.phoneCode : "-"}</td>
                                <td>
                                  {/* {(() => {
                                  const expirationTimestamp = items?.UserMembership?.expiration_at_ms;
                                  if (items?.UserMembership == null) {
                                    return <p style={{color: "red"}}>INACTIVE</p>;
                                  }
                                  else{
                                  if (!expirationTimestamp) {
                                    return "INACTIVE";
                                  }
                                  const currentDate = new Date();
                                  const expirationDate = new Date(parseInt(expirationTimestamp, 10));
                                  return currentDate > expirationDate || items?.UserMembership == null ? <p style={{color:"red"}}>INACTIVE</p> : <p style={{color:"green"}}>ACTIVE</p>;
                                }})()} */}
                                  {items.phoneNumber ? items.phoneNumber : "-"}
                                </td>
                                <td>{items.email ? items.email : "-"}</td>

                                <td>
                                  {(() => {
                                    const expirationTimestamp = items?.UserMembership?.expiration_at_ms;
                                    if (items?.UserMembership == null) {
                                      return <p >NO</p>;
                                    }
                                    else {
                                      if (!expirationTimestamp) {
                                        return "NO";
                                      }
                                      const currentDate = new Date();
                                      const expirationDate = new Date(parseInt(expirationTimestamp, 10));
                                      return currentDate > expirationDate || items?.UserMembership == null ? <p >NO</p> : <p >YES</p>;
                                    }
                                  })()}
                                </td>

                                <td>{items?.UserMembership && items?.UserMembership.code ? 'YES' : 'NO'}</td>
                                <td>
                                  <ul className="d-flex justify-content-center">
                                    <li>
                                      <div
                                        // onClick={() =>
                                        //   getPromptDetails(data.prompt_id)
                                        // }
                                        onClick={() => {
                                          setModal({
                                            show: true,
                                            type: "edit",
                                            id: items.id,
                                          });
                                          onChange(unixTimestampToCustomDate(items?.UserMembership?.expiration_at_ms))
                                          // setInput({
                                          //   promptName: data.name,
                                          //   type: {
                                          //     label: data.type,
                                          //     value: data.type,
                                          //   },
                                          //   category:data.PromptCategories.map((items) => {
                                          //     return {
                                          //       "label": ,
                                          //       "value": "8f382e9a-ea9d-498a-90a1-25a984b1cbe8"
                                          //     }
                                          //   })
                                          // });
                                        }}
                                      >
                                        <span
                                          className="status-p "
                                          data-toggle="modal"
                                          data-target="#exampleModalCenter2"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </td>
                              </tr>) : !spinner && (
                                <tr>
                                  <td colSpan="7">{"No Result found!"}</td>
                                </tr>
                              )}


                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            totalPages={Subscription?.last_page}
            currentPage={Subscription?.current_page}
            page={page}
            setPage={setPage}
          />
        </div>

      </div>
      {modal && <SubsModal
        modal={modal}
        setModal={setModal}
        getSubscriptionList={getSubscriptionList}
        page={page}
        date={new Date()}
        value={value}
        onChange={onChange}
      />}

    </div >
  )
}

export default Subscription