import React, { useState,useEffect } from "react";

const Pagination = ({ totalPages, currentPage, setPage }) => {
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    if (currentPage === 1) {
      setCounter(1); // Reset counter to 1 when currentPage is set to 1
    }
  }, [currentPage]);
  

  const handleNext = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setPage(newPage);
      if (newPage > counter + 9) {
        setCounter(counter + 10); // Move counter ahead by 10
      }
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setPage(newPage);
      if (newPage < counter) {
        setCounter(Math.max(counter - 10, 1)); // Move counter back by 10
      }
    }
  };

  const renderPaginationSteps = () => {
    const links = [];

    // Previous ellipsis
    if (counter > 1) {
      links.push(
        <button
          key="prev-ellipsis"
          className="page-link"
          onClick={() => {
            const newCounter = Math.max(counter - 10, 1);
            setCounter(newCounter);
            setPage(newCounter);
          }}
        >
          ...
        </button>
      );
    }

    // Current page links
    for (let i = counter; i <= totalPages; i++) {
      if (i < counter + 10) {
        links.push(
          <li className={`page-item ${i === currentPage ? "active" : ""}`} key={i}>
            <button className="page-link" onClick={() => setPage(i)}>
              {i}
            </button>
          </li>
        );
      } else if (i === counter + 10) {
        links.push(
          <button
            key="next-ellipsis"
            className="page-link"
            onClick={() => {
              const newCounter = Math.min(counter + 10, totalPages);
              setCounter(newCounter);
              setPage(newCounter);
            }}
          >
            ...
          </button>
        );
        break; // Exit after the ellipsis
      }
    }

    return links;
  };

  return (
    <nav>
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Previous"
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            <span aria-hidden="true"><svg width="14" height="14" viewBox="0 0 14 14"><g transform="translate(14 0) rotate(90)" opacity="1"><g id="arrow-down"><path class="pagination-round" d="M0,0H14V14H0Z" transform="translate(14 14) rotate(180)" fill="none" opacity="0"></path><path class="pagination-arrow" d="M10.555,0,6.21,4.351a1.322,1.322,0,0,1-1.866,0L0,0" transform="translate(1.723 4.964)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></g></g></svg></span>
          </button>
        </li>

        {renderPaginationSteps()}

        <li className="page-item">
          <button
            className="page-link"
            aria-label="Next"
            onClick={handleNext}
            disabled={currentPage >= totalPages}
          >
            <span aria-hidden="true"><svg width="14" height="14" viewBox="0 0 14 14"><g id="vuesax_linear_arrow-down" data-name="vuesax/linear/arrow-down" transform="translate(0 14) rotate(-90)"><g id="arrow-down"><path class="pagination-round" d="M0,0H14V14H0Z" transform="translate(14 14) rotate(180)" fill="none" opacity="0"></path><path class="pagination-arrow" d="M10.555,0,6.21,4.351a1.322,1.322,0,0,1-1.866,0L0,0" transform="translate(1.723 4.964)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></g></g></svg></span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
