import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { useContext } from 'react';

import Login from './Components/onboarding/Login';
import DashboardLayout from './Components/layout/DashboardLayout';
import Category from './Components/category/Category';
import PromptCats from './Components/promptWithCategory/PromptCats';
import StoryFlowWithCats from './Components/storyFlowWithCat/StoryFlowWithCats';
import StoryFlowCats from './Components/storyFlowCats/StoryFlowCats';
import Tags from './Components/tags/Tags';
import Feedback from './Components/feedback/Feedback';
import Programs from './Components/programs/Programs';
import AppContext from './context/AppContext';
import ProgramCats from './Components/programs/ProgramCats';
import ProgramTags from './Components/programs/ProgramTags';
import Prompt from './Components/prompt/Prompt';
import Spinner from './widgets/Spinner';
import ViewShared from './Components/standalone/ViewShared';
import PopularPrompts from './Components/prompt/PopularPrompts';
import Subscription from './Components/subscription/Subscription';
import Promocode from './Components/standalone/Promocode';
import Promocodes from './Components/promocode/Promocodes';

function App() {
  const { spinner, toaster } = useContext(AppContext);

  return (
    <>

      {spinner && <Spinner />}
      {toaster.isShow && (
        ""
      )}
      <HashRouter >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path='/' element={<DashboardLayout />}>
            <Route path='prompts' element={<Prompt />} />
            <Route path='categories' element={<Category />} />
            <Route path='prompts-cats' element={<PromptCats />} />
            <Route path='storyflow-cats' element={<StoryFlowWithCats />} />
            <Route path='storyflow-categories' element={<StoryFlowCats />} />
            <Route path='tags' element={<Tags />} />
            <Route path='feedback' element={<Feedback />} />
            <Route path='programs' element={<Programs />} />
            <Route path='programs-category' element={<ProgramCats />} />
            <Route path='program-tags' element={<ProgramTags />} />
            <Route path='popular-prompts' element={<PopularPrompts />} />
            <Route path='subscription' element={<Subscription />} />
            <Route path='promo-codes' element={<Promocodes />} />
          </Route>
          <Route path='/viewSharedStream/:id' element={<ViewShared />} />
          <Route path='/promocode/:code' element={<Promocode />} />

        </Routes>
      </HashRouter>
    </>

  );
}

export default App;
