import React, { useContext, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import logo from "../../assets/image/logo2.png";
import sidebar from "../../assets/image/sidebar.png";
import crossIcon from "../../assets/image/cross.png";
import Axios from "../../axios/axiosInstance";
import AppContext from "../../context/AppContext";

function Header() {
  const location = useLocation();
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [showOption, setShowOption] = useState({
    progCat: false,
    profilMenu: false,
  });
  const [resetPassword, setResetPassword] = useState({
    show: false,
    oldPass: "",
    newPass: "",
    confirmPass: "",
  });
  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));

  const logoutHandle = () => {
    setSpinner(true);
    Axios
      .get(`${process.env.REACT_APP_BASE_URL}api/auth/logout`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        navigate("/");
        setSpinner(false);
        console.log("response1111111111", response);
        localStorage.removeItem(
          "AUTH_USER_TOKEN_KEY",
          JSON.stringify(response.data.data.access_token)
        );
        navigate("/");
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
      });
  };

  const changePasswordHandle = () => {
    setSpinner(true);
    let obj = {
      oldPassword: resetPassword.oldPass,
      newPassword: resetPassword.newPass,
    };
    Axios
      .post(`${process.env.REACT_APP_BASE_URL}api/auth/changePassword`, obj, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setResetPassword({
          show: false,
          oldPass: "",
          newPass: "",
          confirmPass: "",
        });
        setSpinner(false);
        navigate("/prompts");
      })
      .catch(function (error) {
        setResetPassword({
          show: false,
          oldPass: "",
          newPass: "",
          confirmPass: "",
        });
        setSpinner(false);
        console.log(error);
      });
  };
  console.log("locaton", location);
  return (
    <>
      <div className="mainheader-area">
        {/*class headerPadd */}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 d-flex justify-content-between mobPad">
              <div className="logo">
                <img src={logo} alt="logo" />
                humanStreams
              </div>
              <div className="sidebarIconDiv">
                {/* <img src={this.state.isOpenSidebar ? crossIcon : sidebar} alt="sidebar" className="sidebarImg" onClick={() => this.setState((pre) => { return { isOpenSidebar: !pre.isOpenSidebar } })} /> */}
              </div>
            </div>

            <div className={`col-md-10 clearfix mobileView ${isOpenSidebar ? "showMob" : ""}`}>
              <div
                className={`clearfix d-flex flex-wrap ${isOpenSidebar ? "" : "float-right "} mobUl`}
              >
                <div className="navColor">
                  <ul
                    className="d-flex flex-wrap mobUl"
                    onClick={() => {
                      setIsOpenSidebar(false);
                      // this.props.updateShowMenu(false);
                      // this.props.setShowExtraTabs(false);
                    }}
                  >
                    <li
                      className=""
                      onClick={() => setShowOption({ progCat: false, profilMenu: false })}
                    >
                      <NavLink
                        style={{ color: location?.pathname === "/prompts" ? "#7898ae" : "" }}
                        to="/prompts"
                      >
                        Prompts
                      </NavLink>
                    </li>
                    <div className="extra-tabs d-flex align-items-center justify-content-center m-0 ">
                      <h4 className="user-name">
                        <i
                          className="fa fa-angle-down user-name dropdown-toggle text-dark px-1 m-0"
                          data-toggle="dropdown"
                          onClick={() => {
                            // this.props.setShowExtraTabs(!this.props.showExtraTabs)
                            setShowOption({
                              progCat: false,
                              prompts: !showOption.prompts
                            });
                          }}
                        ></i>
                      </h4>
                      <div className={`dropdown-menu ${showOption.prompts ? "show" : ""}`} style={{ width: "210px" }}>
                        <ul>
                          <li
                            className=""
                            onClick={() => {
                              setShowOption({
                                progCat: false,
                                prompts: !showOption.prompts
                              });
                              setShowOption({ progCat: false, profilMenu: false });
                            }}
                            style={{ padding: "5px" }}
                          >
                            <NavLink to="/categories">Prompts Category</NavLink>
                          </li>
                          <li
                            className=""
                            onClick={() =>
                              setShowOption({
                                progCat: false,
                                prompts: !showOption.prompts
                              })
                            }
                            style={{ padding: "5px" }}
                          >
                            <NavLink to="/prompts-cats">Prompts with Category</NavLink>
                          </li>
                          <li
                            className=""
                            onClick={() =>
                              setShowOption({
                                progCat: false,
                                prompts: !showOption.prompts
                              })
                            }
                            style={{ padding: "5px" }}
                          >
                            <NavLink to="/popular-prompts">Popular Prompts</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <li
                      className=""
                      onClick={() => setShowOption({ progCat: false, profilMenu: false })}
                    >
                      <NavLink
                        style={{
                          color: location?.pathname === "/categories" ? "#7898ae" : "",
                        }}
                        to="/categories"
                      >
                        Prompts Category
                      </NavLink>
                    </li>
                    <li
                      className=""
                      onClick={() => setShowOption({ progCat: false, profilMenu: false })}
                    >
                      <NavLink
                        style={{
                          color: location?.pathname === "/prompts-cats" ? "#7898ae" : "",
                        }}
                        to="/prompts-cats"
                      >
                        Prompts with Category
                      </NavLink>
                    </li> */}
                    {/* <li className="">
                    <NavLink to="/storyflows" >
                      Story Flow
                    </NavLink>
                  </li> */}
                    <li
                      className=""
                      onClick={() => setShowOption({
                        profilMenu: false, progCat: false,
                        prompts: false,
                        storyFlow: !showOption.storyFlow
                      })}
                    >
                      <NavLink
                        style={{
                          color: (location?.pathname === "/storyflow-cats" || location?.pathname === "/storyflow-categories") ? "#7898ae" : "",
                        }}
                      // to="/storyflow-cats"
                      >
                        Story Flow
                      </NavLink>
                    </li>
                    <div className="extra-tabs d-flex align-items-center justify-content-center m-0 ">
                      <h4 className="user-name">
                        <i
                          className="fa fa-angle-down user-name dropdown-toggle text-dark px-1 m-0"
                          data-toggle="dropdown"
                          onClick={() => {
                            // this.props.setShowExtraTabs(!this.props.showExtraTabs)
                            setShowOption({
                              progCat: false,
                              prompts: false,
                              storyFlow: !showOption.storyFlow
                            });
                          }}
                        ></i>
                      </h4>
                      <div className={`dropdown-menu ${showOption.storyFlow ? "show" : ""}`} style={{ width: "210px" }}>
                        <ul>
                          <li
                            className=""
                            onClick={() => {
                              setShowOption({
                                progCat: false,
                                prompts: false,
                                storyFlow: !showOption.storyFlow
                              });
                              // setShowOption({ progCat: false, profilMenu: false });
                            }}
                            style={{ padding: "2px" }}
                          >
                            <NavLink to="/storyflow-cats">Story Flow With Category</NavLink>
                          </li>
                          <li
                            className=""
                            onClick={() =>
                              setShowOption({
                                progCat: false,
                                prompts: false,
                                storyFlow: !showOption.storyFlow
                              })
                            }
                            style={{ padding: "5px" }}
                          >
                            <NavLink to="/storyflow-categories">Story Flow Category</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <li
                      className=""
                      onClick={() => setShowOption({ progCat: false, profilMenu: false })}
                    >
                      <NavLink
                        style={{
                          color: location?.pathname === "/storyflow-categories" ? "#7898ae" : "",
                        }}
                        to="/storyflow-categories"
                      >
                        Story Flow Category
                      </NavLink>
                    </li> */}
                    <li
                      className=""
                      onClick={() => setShowOption({ progCat: false, profilMenu: false })}
                    >
                      <NavLink
                        style={{
                          color: location?.pathname.includes("tags") ? "#7898ae" : "",
                        }}
                        to="/tags"
                      >
                        Tags
                      </NavLink>
                    </li>
                    <li
                      className=""
                      onClick={() => setShowOption({ progCat: false, profilMenu: false })}
                    >
                      <NavLink
                        style={{
                          color: location?.pathname.includes("feedback") ? "#7898ae" : "",
                        }}
                        to="/feedback"
                      >
                        Feedback
                      </NavLink>
                    </li>
                    <li
                      className=""
                      onClick={() => setShowOption({ progCat: false, profilMenu: false })}
                    >
                      <NavLink
                        style={{
                          color: location?.pathname.includes("programs") ? "#7898ae" : "",
                        }}
                        to="/programs"
                      >
                        Programs
                      </NavLink>
                    </li>
                    <div className="extra-tabs d-flex align-items-center justify-content-center m-0 ">
                      <h4 className="user-name">
                        <i
                          className="fa fa-angle-down user-name dropdown-toggle text-dark px-1 m-0"
                          data-toggle="dropdown"
                          onClick={() => {
                            // this.props.setShowExtraTabs(!this.props.showExtraTabs)
                            setShowOption({
                              progCat: !showOption.progCat,
                            });
                          }}
                        ></i>
                      </h4>
                      <div className={`dropdown-menu ${showOption.progCat ? "show" : ""}`}>
                        <ul>
                          <li
                            className=""
                            onClick={() => {
                              setShowOption({
                                progCat: !showOption.progCat,
                              });
                              setShowOption({ progCat: false, profilMenu: false });
                            }}
                          >
                            <NavLink to="/programs-category" style={{ whiteSpace: "nowrap" }}>Program Category</NavLink>
                          </li>
                          <li
                            className=""
                            onClick={() =>
                              setShowOption({
                                progCat: !showOption.progCat,
                              })
                            }
                          >
                            <NavLink to="/program-tags" style={{ whiteSpace: "nowrap" }}>Program Tags</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <li
                      className=""
                      onClick={() => setShowOption({ progCat: false, profilMenu: false })}
                    >
                      <NavLink
                        style={{
                          color: location?.pathname.includes("subscription") ? "#7898ae" : "",
                        }}
                        to="/subscription"
                      >
                        Users
                      </NavLink>
                    </li>
                    <li
                      className=""
                      onClick={() => setShowOption({ progCat: false, profilMenu: false })}
                    >
                      <NavLink
                        style={{
                          color: location?.pathname.includes("promo-codes") ? "#7898ae" : "",
                        }}
                        to="/promo-codes"
                      >
                        Promo Codes
                      </NavLink>
                    </li>
                  </ul>
                </div>


                <div className="user-profile m-0">
                  <h4
                    className="user-name dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={() =>
                      setShowOption({
                        profilMenu: !showOption.profilMenu,
                      })
                    }
                  >
                    Testing
                    {/* {this.state.uname.length > 10 ? this.state.uname.substring(0, 9) : this.state.uname}  */}
                    <i className="fa fa-angle-down"></i>
                  </h4>
                  <div
                    className={`dropdown-menu ${showOption.profilMenu ? "show" : ""}`}
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    <a className="dropdown-item" onClick={() => setResetPassword({ show: true })}>
                      Change Password
                    </a>
                    <a className="dropdown-item" onClick={() => logoutHandle()}>
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* reset password */}

      <div
        className={`modal fade ${resetPassword.show ? "show" : ""}`}
        style={{ display: `${resetPassword.show ? "block" : "none"}` }}
        id="exampleModalCenter"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="login-area p-0">
              <div className="login-box d-block  text-center">
                <form className="m-0">
                  <button
                    style={{ marginRight: "10px", color: "white" }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() =>
                      setResetPassword({
                        show: false,
                        oldPass: "",
                        newPass: "",
                        confirmPass: "",
                      })
                    }
                  >
                    <span>&times;</span>
                  </button>
                  <div className="login-form-head">
                    <h4>Reset Password</h4>
                    <p>Hey! Reset Your Password and comeback again</p>
                  </div>

                  <div className="login-form-body">
                    <div className="form-gp">
                      <label htmlFor="exampleInputPassword1">Old Password</label>
                      <br />
                      <input
                        type="password"
                        id="exampleInputPassword1"
                        name="oldPassword"
                        value={resetPassword.oldPass}
                        onChange={(e) =>
                          setResetPassword((prev) => ({
                            ...prev,
                            oldPass: e.target.value,
                          }))
                        }
                      // onChange={(e) => this.updateFields(e, "oldpassword")}
                      // onBlur={(e) => this.validateFields("oldpassword")}
                      />
                      <i className="ti-lock"></i>
                      <p style={{ color: "red" }}>
                        {/* {this.state.errors.oldpassword
                        ? this.state.errors.oldpassword
                        : ""} */}
                      </p>
                    </div>

                    <div className="form-gp">
                      <label htmlFor="exampleInputPassword2">New Password</label>
                      <br />
                      <input
                        type="password"
                        id="exampleInputPassword2"
                        name="newPassword"
                        value={resetPassword.newPass}
                        onChange={(e) =>
                          setResetPassword((prev) => ({
                            ...prev,
                            newPass: e.target.value,
                          }))
                        }
                      // value={this.state.newpassword}
                      // onChange={(e) => this.updateFields(e, "newpassword")}
                      // onBlur={(e) => this.validateFields("newpassword")}
                      />
                      <i className="ti-lock"></i>
                      <p style={{ color: "red" }}>
                        {/* {this.state.errors.newpassword
                        ? this.state.errors.newpassword
                        : ""} */}
                      </p>
                    </div>

                    <div className="form-gp">
                      <label htmlFor="exampleInputPassword3">Confirm Password</label>
                      <br />
                      <input
                        type="password"
                        id="exampleInputPassword3"
                        name="confirmPassword"
                        value={resetPassword.confirmPass}
                        onChange={(e) =>
                          setResetPassword((prev) => ({
                            ...prev,
                            confirmPass: e.target.value,
                          }))
                        }
                      // value={this.state.confirmpassword}
                      // onChange={(e) =>
                      //   this.updateFields(e, "confirmpassword")
                      // }
                      // onBlur={(e) => this.validateFields("confirmpassword")}
                      />
                      <i className="ti-lock"></i>
                      <p style={{ color: "red" }}>
                        {/* {this.state.errors.confirmpassword
                        ? this.state.errors.confirmpassword
                        : ""} */}
                      </p>
                    </div>
                    <p style={{ color: "red" }}>{/* {this.state.errMessage} */}</p>
                    <div className="submit-btn-area mt-5 d-flex">
                      <a
                        onClick={() =>
                          setResetPassword({
                            show: false,
                            oldPass: "",
                            newPass: "",
                            confirmPass: "",
                          })
                        }
                        id="form_submit"
                        className="mr-2"
                        type="button"
                        style={{ cursor: "pointer" }}
                      >
                        Cancel
                      </a>
                      <a
                        // onClick={() => this.changePassword()}
                        id="form_submit"
                        type="button"
                        style={{ cursor: "pointer" }}
                        onClick={() => changePasswordHandle()}
                      >
                        Reset
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
