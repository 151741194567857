import React, { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Axios from '../../axios/axiosInstance';
import AppContext from '../../context/AppContext';
import Pagination from "../../widgets/Pagination";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import tooltip from "../../assets/image/tooltip.svg";



const Promocodes = () => {
    // State to manage the visibility of the modal
    const [modal, setModal] = useState({
        show: false,
        type: 'Add',
        id: null
    });
    const [searchQuery, setSearchQuery] = useState('');

    // Context to access global spinner state and setter function
    const { spinner, setSpinner } = useContext(AppContext);

    // State to store the list of promo codes and users
    const [promoCodeList, setPromoCodes] = useState([]);
    const [userList, setUserList] = useState([]);

    // State to manage pagination for promo codes and users
    const [page, setPage] = useState(1);
    const [userPage, setUserPage] = useState(1);

    // State to check if there are more users to load and if the dropdown is open
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // State to manage the selected user and code name input
    const [selectedUser, setSelectedUser] = useState(null);
    const [codeName, setCodeName] = useState('');

    const [dateValue, onChange] = useState(new Date());
    const [description, setDescription] = useState(null);


    // References to the dropdown select element and the search input
    const selectRef = useRef(null);
    const inputRef = useRef(null);

    // Retrieve the token from local storage for authentication
    const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));

    // Close the modal and reset relevant states
    const handleClose = () => {
        setModal({
            show: false,
            type: 'Add',
            id: null
        });

        // setShow(false);
        setUserList([]);
        setUserPage(1);
        setHasMoreUsers(true);
        setIsDropdownOpen(false);
        setSelectedUser(null);
        setCodeName('');
        setSearchQuery('');
        setDescription(null);
        onChange(new Date());
    };

    // Show the modal and fetch the user list
    const handleShow = () => {
        setModal({
            show: true,
            type: 'Add',
            id: null
        });
        // setShow(true);
        getAllUserList(1);
    };

    // Handle search input change
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        // Reset user list and page when search query changes
        setUserList([]);
        setUserPage(1);
        setHasMoreUsers(true);

        // Fetch the user list with the new search query
        getAllUserList(1, query);
    };

    // Fetch the list of promo codes with pagination
    const getPromoCodes = (page) => {
        setSpinner(true);
        Axios.get(`${process.env.REACT_APP_BASE_URL}api/PromoCode?page=${page}&limit=10`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setPromoCodes(response?.data);
                setSpinner(false);
            })
            .catch(() => {
                setSpinner(false);
            });
    };

    // Fetch the list of users with pagination
    const getAllUserList = (page, query = '') => {
        setSpinner(true);
        Axios.get(`${process.env.REACT_APP_BASE_URL}api/user?page=${page}&limit=10&search=${query}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response?.data?.data?.length) {
                    setUserList(prev => [...prev, ...response.data.data]);
                    setHasMoreUsers(response.data.next_page_url !== null);
                } else {
                    setHasMoreUsers(false);
                }
                setSpinner(false);
            })
            .catch(() => {
                setSpinner(false);
            });
    };

    // Handle the scroll event to load more users when the dropdown is scrolled to the bottom
    const handleUserScroll = (e) => {
        const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        if (bottom && hasMoreUsers && !spinner) {
            setUserPage(prev => prev + 1);
            getAllUserList(userPage + 1);
        }
    };

    // Toggle the dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen(prev => !prev);
    };

    // Handle the selection of a user from the dropdown
    const handleOptionClick = (user) => {

        setSelectedUser(user);
        // Close the dropdown only when an item is selected
        setIsDropdownOpen(false);
    };

    // Update the code name state on input change
    const handleCodeNameChange = (e) => {
        setCodeName(e.target.value);
    };

    // Handle the creation of a new promo code
    const handleCreate = () => {
        if (!codeName || !selectedUser || !description) {
            alert("Please enter the Code Name and select a user and description.");
            return;
        }

        const isEditMode = modal?.type === 'Edit';
        const url = isEditMode ? `api/PromoCode/${modal?.id}` : 'api/PromoCode';
        const body = {
            ...(isEditMode ? {} : { code: codeName, User: selectedUser.id }),
            expiry: moment.utc(dateValue).format('YYYY-MM-DD HH:mm:ss'),
            description,
        };

        const apiMethod = isEditMode ? Axios.put : Axios.post;

        setSpinner(true);
        apiMethod(`${process.env.REACT_APP_BASE_URL}${url}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(() => {
                handleClose();
                getPromoCodes(page);
            })
            .catch((error) => {
                console.error("Error during API call:", error);
            })
            .finally(() => {
                setSpinner(false);
            });
    };


    // Copy the promo code link to the clipboard
    const copyToClipboard = async (linkCode) => {
        try {
            const promoLink = `${process.env.REACT_APP_PROMO_LINK}/#/promocode/${linkCode}`; // For live server
            await navigator.clipboard.writeText(promoLink);
            alert('Copied!')
        } catch (error) {
            console.error('Failed to copy the value:', error);
        }
    };


    //Deactivate a promocode
    const deActivatePromo = (promoId) => {
        setSpinner(true)
        Axios
            .put(
                `${process.env.REACT_APP_BASE_URL}api/PromoCode/${promoId}`,
                { "isActive": false },

                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            )
            .then(function () {
                setSpinner(false);
                getPromoCodes(page);
            })
            .catch(function (error) {
                setSpinner(false);
            });
    }


    // Fetch the promo codes initially and when the page changes
    useEffect(() => {
        getPromoCodes(page);
    }, [page]);

    // Handle clicks outside of the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target) && inputRef.current && !inputRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <>
            <div className="body-bg">
                <div className="horizontal-main-wrapper">
                    <div className="main-content-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mt-5">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                <div className="listHead">Promo Codes</div>
                                                <button onClick={handleShow} className='btn btn-info shadow-none'>
                                                    Create Code
                                                </button>
                                            </div>
                                            <div className="single-table">
                                                <div className="table-responsive">
                                                    <table className="table table-hover progress-table text-center">
                                                        <thead className='text-uppercase'>
                                                            <tr>
                                                                <th>NO</th>
                                                                <th>THERAPIST NAME</th>
                                                                <th>CODE</th>
                                                                <th>EXPIRY DATE</th>
                                                                <th>PLAN DESCRIPTION</th>

                                                                <th>
                                                                    CLICKED
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-clicked" className="custom-tooltip">
                                                                                Count Of Users Who Just Simply Clicked On It.
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="bi bi-info-circle ms-2 pl-1" style={{ cursor: 'pointer' }}><img src={tooltip} /></i>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    REGISTERED
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-registered" className="custom-tooltip">
                                                                                Count Of Users Who Registered And Went Inside The App Successfully And Saw 30 Days Popup
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="bi bi-info-circle ms-2 pl-1" style={{ cursor: 'pointer' }}><img src={tooltip} /></i>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    30 DAYS ACTIVE
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-30-days" className="custom-tooltip">
                                                                                Count Of Users Who Are In Their 30 Day Free Period.
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="bi bi-info-circle ms-2 pl-1" style={{ cursor: 'pointer' }}><img src={tooltip} /></i>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    REDEEMED
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-redeemed" className="custom-tooltip">
                                                                                Count Of Users Who Redeemed The Code From App Store After 30 Days
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="bi bi-info-circle ms-2 pl-1" style={{ cursor: 'pointer' }}><img src={tooltip} /></i>
                                                                    </OverlayTrigger>
                                                                </th>

                                                                <th>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {promoCodeList?.data?.length ? (
                                                                promoCodeList?.data?.map((data, index) => (
                                                                    <tr key={index} className={!data.isActive ? 'disabled-row' : ''}>
                                                                        <th>{index + 1}</th>
                                                                        <td>
                                                                            {data?.User?.firstName ? `${data?.User?.firstName || ''} ${data?.User?.lastName || ''}` : `${data?.User?.phoneCode + data?.User?.phoneNumber || ''}`}
                                                                        </td>
                                                                        <td>{data?.code}</td>
                                                                        <td>{data?.expiry ? moment(data?.expiry).format('MM-DD-YYYY') : '-'}</td>
                                                                        <td>{data?.description ? data?.description : '-'}</td>
                                                                        <td>{data?.clickCount}</td>
                                                                        <td>{data?.signupCount}</td>
                                                                        <td>{data?.activeUsersCount}</td>
                                                                        <td>{data?.redeemCount}</td>
                                                                        <td className='d-flex justify-content-between'>
                                                                            <img title="Copy Link" src="./assets/image/copyicon.svg" onClick={() => copyToClipboard(data?.code)} alt="icon" className='tbl_action_icon' />
                                                                            <div
                                                                                onClick={() => {
                                                                                    setCodeName(data.code);
                                                                                    setSelectedUser(data?.User);
                                                                                    onChange(new Date(data?.expiry));
                                                                                    setDescription(data?.description);
                                                                                    setModal({
                                                                                        show: true,
                                                                                        type: 'Edit',
                                                                                        id: data.id
                                                                                    })
                                                                                }} >
                                                                                <span
                                                                                    title="Edit"
                                                                                    className="status-p mr-0"
                                                                                    data-toggle="modal"
                                                                                    data-target="#exampleModalCenter2"
                                                                                >
                                                                                    <i className="fa fa-pencil"></i>
                                                                                </span>

                                                                            </div>
                                                                            <img  title="Deactivate" src="./assets/image/deactivateTab.svg" onClick={() => deActivatePromo(data?.id)} alt="icon" className='tbl_action_icon' />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : !spinner && (
                                                                <tr>
                                                                    <td colSpan="6">No Results Found!</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Pagination
                            totalPages={promoCodeList?.last_page}
                            currentPage={promoCodeList?.current_page}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                </div>
            </div>

            <Modal show={modal.show} onHide={handleClose} className='modalpr'>
                <Modal.Header closeButton>
                    <h5 className='modal-title'>{modal?.type} {modal?.type === 'Add' && 'New'} Code</h5>
                    <img src="./assets/image/modalclose.svg" alt="icon" className='modal-close' onClick={handleClose} />
                </Modal.Header>
                <Modal.Body className='row'>
                    <div className="col-md-12 form-group">
                        <label className='col-form-label'>Code Name</label>
                        <input
                            type="text"
                            className='form-control editCode'
                            value={codeName}
                            onChange={handleCodeNameChange}  // Update code name on input change
                            readOnly={modal?.type === 'Edit'}
                        />
                    </div>

                    <div className="col-md-12 form-group">
                        <label className='col-form-label'>Assign To</label>
                        <div className={`custom-select-box ${modal?.type === 'Edit' ? '' : 'addCursor'}`} ref={selectRef}>
                            <div className="select-box-selected" onClick={modal?.type === 'Edit' ? null : toggleDropdown}>
                                {selectedUser ? `${selectedUser.phoneCode} ${selectedUser.phoneNumber}` : "Select User"}
                            </div>
                            {isDropdownOpen && modal?.type !== 'Edit' && (
                                <div
                                    ref={selectRef}
                                    onScroll={handleUserScroll}
                                    className="select-box-options"
                                    style={{ maxHeight: '200px', overflowY: 'auto' }}
                                >
                                    <input
                                        type="text"
                                        className='form-control mb-2'
                                        placeholder="Search Users"
                                        value={searchQuery}
                                        onChange={handleSearchChange}  // Update search query on input change
                                        ref={inputRef}
                                    />
                                    {userList.length ? (
                                        userList.map((user, index) => (
                                            <div
                                                key={index}
                                                className="select-box-option"
                                                onClick={() => handleOptionClick(user)}
                                            >
                                                {`${user.phoneCode} ${user.phoneNumber}`}
                                            </div>
                                        ))
                                    ) : (
                                        <div>No Users Found</div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 form-group  cusdate">
                        <label className='col-form-label'>Expiry Date</label>
                        <DatePicker className='form-control b-0' onChange={onChange} value={dateValue} />
                    </div>
                    <div className="col-md-12 form-group">
                        <label className='col-form-label'>Plan Description</label>
                        <input
                            type="text"
                            className='form-control'
                            placeholder='e.g. $5 per month'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>

                </Modal.Body>

                <Modal.Footer className='justify-content-center'>
                    <button className='btn btn-info shadow-none' onClick={handleCreate}>Create</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Promocodes;
